import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { HealthcheckCoreService } from '@qtek/libs/healthcheck-core';
import { MetaCoreActions, MetaCoreFeature } from '@qtek/libs/meta-core';
import {
  ErrorSnackBarData,
  NormalSnackBarData,
  SnackBarService,
} from '@qtek/libs/snack-bar-core';
import {
  TranslationCoreActions,
  TranslationCoreFeature,
} from '@qtek/libs/translation-core';
import { QtNetError } from '@qtek/shared/components';
import { Language, LanguageOption } from '@qtek/shared/models';
import { OnlineService } from '@qtek/shared/services';
import { getLinks, isNonNullable } from '@qtek/shared/utils';
import {
  combineLatest,
  combineLatestWith,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  first,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';
import { LoginSteps, PortalType, RegisterData } from '../../models';
import { MfaLoginActions } from '../../store/mfa-login.actions';
import { MfaLoginFeature } from '../../store/mfa-login.reducer';

@Component({
  selector: 'qt-mfa-login',
  templateUrl: './mfa-login.component.html',
  styleUrls: ['./mfa-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MfaLoginComponent implements OnInit, OnDestroy {
  form = this.formBuilder.group({
    email: this.formBuilder.control(''),
    password: this.formBuilder.control(''),
    mfaCode: this.formBuilder.control(''),
    saveMachine: this.formBuilder.control(false),
  });

  destroySubject = new Subject<void>();
  destroy$ = this.destroySubject.asObservable();

  triggerMfaCodeSubject = new Subject<void>();
  triggerMfaCode$ = this.triggerMfaCodeSubject.asObservable();

  triggerChangeStepSubject = new Subject<LoginSteps>();

  LoginSteps = LoginSteps;
  currentStep: LoginSteps = LoginSteps.EMAIL;

  getLanguages$ = this.store.select(MetaCoreFeature.selectLanguages);
  getSelectedLanguage$ = this.store.select(TranslationCoreFeature.selectLang);

  getTermsAndPrivacyLinks$ = combineLatest([
    this.getSelectedLanguage$.pipe(map(selectedLang => getLinks(selectedLang))),
    this.store.select(MetaCoreFeature.selectGuiOnlineBookDomain),
  ]).pipe(
    map(([termsAndPrivacy, domain]) => ({
      terms: domain + termsAndPrivacy.terms,
      privacy: domain + termsAndPrivacy.privacy,
    }))
  );

  email$ = this.store.select(MfaLoginFeature.selectEmail);
  firstName$ = this.store.select(MfaLoginFeature.selectFn);
  emailIsEditable$ = this.store.select(MfaLoginFeature.selectEmailIsEditable);
  name = '';
  token$ = this.store.select(MetaCoreFeature.selectToken).pipe(shareReplay(1));

  passwordToken: string | undefined = undefined;
  resetPasswordToken: string | undefined = undefined;
  promo = '';
  phn = '';
  PortalType = PortalType;

  networkError$: Observable<QtNetError | null> = of(null);

  errorMessageSubscription = this.store
    .select(MfaLoginFeature.selectErrorMessage)
    .pipe(
      distinctUntilChanged(),
      filter(isNonNullable),
      withLatestFrom(
        this.store.select(MfaLoginFeature.selectErrorMessageParams)
      ),
      tap(([message, params]) => {
        console.log(message, params);
        this.showErrorSnackBar(message, params);
      }),
      takeUntilDestroyed()
    )
    .subscribe();

  validationErrorMessage$ = this.store
    .select(MfaLoginFeature.selectValidationErrorMessage)
    .pipe(
      distinctUntilChanged(),
      combineLatestWith(
        this.store.select(MfaLoginFeature.selectValidationErrorMessageParams)
      ),
      map(([errorMessage, params]) => {
        return {
          errorTranslationKey: errorMessage,
          params,
          random: Math.random(),
        };
      })
    );

  requiredMfaCodeSubscription = this.store
    .select(MfaLoginFeature.selectRequireMfaCode)
    .pipe(
      filter(trigger => trigger),
      tap(() => {
        this.triggerMfaCodeSubject.next();
        this.changeDetectorRef.markForCheck();
      }),
      takeUntilDestroyed()
    )
    .subscribe();

  changeStepSubjectSubscription = this.triggerChangeStepSubject
    .asObservable()
    .pipe(
      tap(newStep => {
        this.currentStep = newStep;
        this.changeDetectorRef.markForCheck();
      }),
      takeUntilDestroyed()
    )
    .subscribe();

  activatedRouteSubscription = this.activatedRoute.queryParamMap
    .pipe(
      mergeMap(params => {
        if (Object.keys(params).length === 0) {
          return EMPTY;
        }

        const decryptedParams = this.encryptedParamsToObject(params['keys'][0]);

        if (decryptedParams['t']) {
          this.resetPasswordToken = decryptedParams['t'];
        }

        if (decryptedParams['tkn']) {
          this.passwordToken = decryptedParams['tkn'];
        }

        if (!!decryptedParams['email'] || !!decryptedParams['e']) {
          const emailKey = decryptedParams['email'] ? 'email' : 'e';
          const email = decryptedParams[emailKey];
          this.form.get('email').patchValue(email, { emitEvent: true });
          this.store.dispatch(MfaLoginActions.setEmail({ email }));
          this.store.dispatch(
            MfaLoginActions.setEmailEditable({ isEditable: false })
          );

          if (this.resetPasswordToken) {
            this.currentStep = LoginSteps.RESET_PASSWORD_CONFIRM;
          } else {
            this.currentStep = LoginSteps.PASSWORD;
          }
        }

        if (decryptedParams['returnUrl']) {
          const returnUrl = decryptedParams['returnUrl'];
          this.store.dispatch(MfaLoginActions.setReturnUrl({ returnUrl }));
        }

        if (decryptedParams['fn']) {
          const fn = decryptedParams['fn'];
          this.store.dispatch(MfaLoginActions.setFirstName({ fn }));
        }

        if (decryptedParams['reqPasswd']) {
          const reqPasswd = decryptedParams['reqPasswd'];
          if (reqPasswd === 'true') {
            this.currentStep = LoginSteps.REGISTER;
          }
        }

        return EMPTY;
      }),
      takeUntilDestroyed()
    )
    .subscribe();

  requestedRedirectSubscription = this.store
    .select(MfaLoginFeature.selectRequestedRedirect)
    .pipe(
      filter(requestedRedirect => requestedRedirect),
      combineLatestWith(
        this.store.select(MfaLoginFeature.selectReturnUrl),
        this.store.select(MetaCoreFeature.selectGuiDomain)
      ),
      tap(([, returnUrl, domain]) => {
        window.location.href = `${domain}/${returnUrl ? returnUrl : ''}`;
      }),
      takeUntilDestroyed()
    )
    .subscribe();

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private online: OnlineService,
    private healthCheck: HealthcheckCoreService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(MetaCoreActions.loadMetaGuiOnlineBookDomain());
    this.store.dispatch(MetaCoreActions.loadMetaLanguages());
    this.name =
      this.activatedRoute.snapshot.queryParams['firstName'] ||
      atob(this.activatedRoute.snapshot.queryParams['fn'] || '');
    this.promo = this.activatedRoute.snapshot.queryParams['.promo'];
    this.phn = this.activatedRoute.snapshot.queryParams['.phn'];

    const isWSHealth = this.healthCheck.isWebSocketAlive$.pipe(
      switchMap(value => {
        if (value) {
          return of(value);
        }
        return timer(2000).pipe(map(() => value));
      })
    );

    const isHttpHealth = this.healthCheck.isHealthyHttpConnection$.pipe(
      switchMap(value => {
        if (value) {
          return of(value);
        }
        return timer(2000).pipe(map(() => value));
      })
    );

    this.networkError$ = combineLatest([
      isWSHealth,
      isHttpHealth,
      this.online.change$,
    ]).pipe(
      map(([isWSHealth, isHttpHealth, isOnline]) => {
        if (!isOnline) {
          return 'offline';
        }
        if (!isHttpHealth) {
          return 'http';
        }
        if (!isWSHealth) {
          return 'ws';
        }
        return null;
      }),
      distinctUntilChanged(),
      debounceTime(250)
    );
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  handleChangeLanguage(language: Language): void {
    this.store.dispatch(TranslationCoreActions.setLanguage({ language }));
  }

  handleSubmitEmail(email: string): void {
    this.store.dispatch(MfaLoginActions.setEmail({ email }));
    this.store
      .select(MfaLoginFeature.selectEmail)
      .pipe(
        filter(selectedEmail => selectedEmail === email),
        tap(() => {
          this.currentStep = LoginSteps.PASSWORD;
          this.form.controls.email.patchValue(email);
          this.changeDetectorRef.markForCheck();
        }),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  handleSubmitResetPassword(resetPasswrd: {
    pass1: string;
    pass2: string;
  }): void {
    this.store.dispatch(MfaLoginActions.resetSnackBarMessages());

    const payload = {
      pass1: resetPasswrd.pass1,
      pass2: resetPasswrd.pass2,
      email: this.form.get('email').value,
      rstToken: this.resetPasswordToken,
    };

    this.store
      .select(MfaLoginFeature.selectNormalMessage)
      .pipe(
        filter(isNonNullable),
        combineLatestWith(
          this.store.select(MfaLoginFeature.selectNormalMessageParams)
        ),
        tap(([message, params]) => {
          this.showNormalSnackBar(message, params);

          this.triggerChangeStepSubject.next(LoginSteps.PASSWORD);
        }),
        takeUntil(
          merge(
            this.destroy$,
            this.store.select(MfaLoginFeature.selectErrorMessage).pipe(
              filter(isNonNullable)
              // combineLatestWith(
              //   this.store.select(MfaLoginFeature.selectErrorMessageParams)
              // ),
              // tap(([message, params]) => {
              //   this.showErrorSnackBar(message, params);
              // }),
              // take(1)
            )
          )
        ),
        first()
      )
      .subscribe();

    this.store.dispatch(MfaLoginActions.resetPasswordConfirm(payload));
  }

  handleSubmitMfaCode(payload: { mfaCode: string; saveDevice: boolean }): void {
    this.store.dispatch(
      MfaLoginActions.setMfaCode({
        mfaCode: payload.mfaCode,
        saveDevice: payload.saveDevice,
      })
    );

    combineLatest([
      this.store.select(MfaLoginFeature.selectEmail),
      this.store.select(MfaLoginFeature.selectPasswd),
      this.store.select(MfaLoginFeature.selectMfa_code),
      this.store.select(MfaLoginFeature.selectSaveDevice),
    ])
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(([email, password, mfa_code, saveDevice]) => {
        this.store.dispatch(
          MfaLoginActions.mfaLogin({
            email,
            passwd: password,
            mfa_code,
            saveDevice,
          })
        );
      });
  }

  handleSubmitLostPassword(): void {
    this.triggerChangeStepSubject.next(LoginSteps.RESET_PASSWORD);
  }

  handleSubmitPassword(password: string): void {
    this.store.dispatch(MfaLoginActions.setPassword({ passwd: password }));
    combineLatest([
      this.store.select(MfaLoginFeature.selectEmail),
      this.store.select(MfaLoginFeature.selectPasswd),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([email, password]) => {
        this.store.dispatch(
          MfaLoginActions.standardLogin({ email, passwd: password })
        );
      });
  }

  handleSubmitRememberPassword(email: string): void {
    this.store.dispatch(MfaLoginActions.resetSnackBarMessages());

    this.store
      .select(MfaLoginFeature.selectNormalMessage)
      .pipe(
        filter(isNonNullable),
        combineLatestWith(
          this.store.select(MfaLoginFeature.selectNormalMessageParams)
        ),
        tap(([message, params]) => {
          this.showNormalSnackBar(message, params || []);
          this.triggerChangeStepSubject.next(LoginSteps.EMAIL);
        }),
        takeUntil(
          merge(
            this.destroy$,
            this.store.select(MfaLoginFeature.selectErrorMessage).pipe(
              filter(isNonNullable)
              // combineLatestWith(
              //   this.store.select(MfaLoginFeature.selectErrorMessageParams)
              // ),
              // tap(([message, params]) => {
              //   console.log(message, params);
              //   this.showErrorSnackBar(message, params);
              // }),
              // take(1)
            )
          )
        ),
        first()
      )
      .subscribe();

    this.store.dispatch(MfaLoginActions.resetPassword({ email }));
  }

  handleSubmitRegister(payload: RegisterData): void {
    this.getSelectedLanguage$
      .pipe(
        tap(lang => {
          this.store.dispatch(
            MfaLoginActions.standardRegister({
              email: payload.email,
              passwd: payload.password,
              gdprFlag: payload.gdprFlag,
              lng: lang,
              tkn: this.passwordToken,
            })
          );
        }),
        take(1)
      )
      .subscribe();
  }

  findLanguageName(key: string, languages: LanguageOption[]): string {
    return languages.find(el => el.id === key)?.name ?? '';
  }

  private showErrorSnackBar(message: string, params: string[] = []): void {
    const snackBarData: ErrorSnackBarData = {
      type: 'ErrorMessage',
      message,
      params,
    };

    this.snackBarService.showSnackBar(snackBarData);
  }

  private showNormalSnackBar(message: string, params: string[] = []): void {
    const snackBarData: NormalSnackBarData = {
      type: 'NormalMessage',
      message,
      params,
    };

    this.snackBarService.showSnackBar(snackBarData);
  }

  private base64ToString(value: string | null): string {
    if (!value) {
      return '';
    }
    const preparedValue = value.replace(/-/g, '+').replace(/_/g, '/');
    try {
      return atob(preparedValue);
    } catch (e) {
      console.error(e);
    }
    return '';
  }

  private encryptedParamsToObject(base64: string): { [key: string]: string } {
    const decryptedString = this.base64ToString(base64);
    const arrayParamsWithValues = decryptedString.split('&');
    return arrayParamsWithValues.reduce((acc, val) => {
      const index = val.indexOf('=');
      const key = val.slice(0, index);
      const value = val.slice(index + 1);
      return {
        ...acc,
        [key]: value,
      };
    }, {});
  }
}
