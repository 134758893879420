import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PasswordValidators } from '../../utils/password-validator';

@Component({
  selector: 'qt-login-reset-password-confirm',
  templateUrl: './login-reset-password-confirm.component.html',
  styleUrls: ['./login-reset-password-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoginResetPasswordConfirmComponent {
  @Input() email: string | null = null;
  @Output() submitClick = new EventEmitter<{ pass1: string; pass2: string }>();

  readonly minPassLength = 8;

  showPassword = false;

  form = this.formBuilder.group(
    {
      pass1: this.formBuilder.control('', [
        Validators.minLength(this.minPassLength),
        Validators.required,
        PasswordValidators.passwordDigitRequired,
        PasswordValidators.passwordLowercaseRequired,
        PasswordValidators.passwordUppercaseRequired,
        PasswordValidators.passwordSpecialCharacterRequired,
      ]),
      pass2: this.formBuilder.control('', [Validators.required]),
    },
    {
      validators: [
        PasswordValidators.confirmedPasswordValidator('pass1', 'pass2'),
      ],
    }
  );
  constructor(private formBuilder: FormBuilder) {}

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  isPasswordNotConfirmed(): boolean {
    return (
      this.form.controls['pass2'].errors?.['confirmedPasswordValidator'] ||
      this.form.controls['pass2'].errors?.['required']
    );
  }

  handleSubmit(): void {
    this.form.get('pass1').updateValueAndValidity();
    this.form.get('pass2').updateValueAndValidity();

    if (
      this.form.get('pass1').valid &&
      this.form.get('pass2').valid &&
      this.form.valid
    ) {
      this.submitClick.emit(this.form.getRawValue());
    }
  }
}
