import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HTTP_BASE_URL } from '@qtek/core/api-core';
// import { LazyDialogLoader } from '@qtek/libs/dialog-loader';
import { HealthcheckCoreService } from '@qtek/libs/healthcheck-core';
import { QtHttpInterceptor } from '../qtek-interceptor';

@Injectable()
export class QtNetworkErrorHandlerInterceptor implements QtHttpInterceptor {
  readonly qtek = true;

  constructor(
    @Inject(HTTP_BASE_URL) private baseUrl: string,
    // private matDialog: MatDialog,
    private router: Router,
    private healthcheck: HealthcheckCoreService
    // private dialogService: LazyDialogLoader
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Not a backend call, don't try to revalidate.
    // TODO(marcincichocki) use metadata instead of url when available
    if (
      !req.url.startsWith(this.baseUrl) ||
      req.url.includes('/api/v1/service/logout')
    ) {
      return next.handle(req);
    }

    // RRRR: Remove req
    return next
      .handle(req)
      .pipe(
        catchError((response: HttpErrorResponse) => this.handleError(response))
      );
  }

  handleError(response: HttpErrorResponse) {
    switch (response.status) {
      case 504:
      case 0: {
        this.handleNoConnection(response);
        break;
      }

      default: {
        this.handleOtherStatusCode(response);
        break;
      }
    }

    return throwError(() => new Error(response.error));
  }

  handleNoConnection(response: HttpErrorResponse) {
    this.healthcheck.setHttpUnhealthy();
    this.saveErrorToDataLayer(response);

    // this.matDialog.closeAll();
    this.router.navigate(['error', 'network']);
  }

  /**
   * Handle all other status codes.
   *
   * @param response Server response, can be used to gather error info.
   */
  handleOtherStatusCode(response: HttpErrorResponse) {
    this.saveErrorToDataLayer(response);

    // const { sts } = response.error;
    // const errors = Object.keys(sts && sts.attrs ? sts.attrs : {}).map(
    //   key => sts.attrs[key]
    // );
    // const main = errors.find(error => error.main) || {
    //   msg: 'ERR_DEFAULT_MSG',
    // };
    // const details = errors.filter(error => !error.main);
  }

  /**
   * Send error to google tag manager
   *
   */
  saveErrorToDataLayer(response: HttpErrorResponse) {
    if ((<any>window).dataLayer) {
      (<any>window).dataLayer.push({
        event: 'error',
        event_category: 'http_error',
        event_value: response.error,
      });
    }
  }
}
